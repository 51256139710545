import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import HomePage from "./pages/HomePage/HomePage";
import OlinkaPage from "./pages/OlinkaPage/OlinkaPage";



function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/chalupa-106" element={<OlinkaPage />} />
      </Routes>
    </Router>
    
  );
}

export default App;

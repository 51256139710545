import React from "react";
import {
  Column,
  Container,
  Label,
  Layout,
  Row,
  TableContainer,
  TitleDiv,
  Value,
} from "./styles";
import { colors } from "../../../../theme";
import { useFadeAnmation } from "hooks/useFadeAnimation";

import SecondaryTitle from "../../../../components/UI/SecondaryTitle/SecondaryTitle";

const OverviewSection = () => {
  const { isVisible, ref } = useFadeAnmation();

  return (
    <Layout ref={ref} className={isVisible ? "visible" : ""}>
      <Container>
        <TitleDiv>
          <SecondaryTitle color={colors.black}>Overview</SecondaryTitle>
        </TitleDiv>
        <TableContainer>
          <Column>
            <Row>
              <Label>Počet osob</Label>
              <Value>1-10</Value>
            </Row>
            <Row>
              <Label>Wifi</Label>
              <Value>Ano</Value>
            </Row>
            <Row>
              <Label>Domácí mazlíčci</Label>
              <Value>Ano</Value>
            </Row>
            <Row>
              <Label>Parkování</Label>
              <Value>Ano</Value>
            </Row>
          </Column>
          <Column>
            <Row>
              <Label>Noc/objekt</Label>
              <Value>Připravujeme</Value>
            </Row>
            <Row>
              <Label>Víkend/objekt</Label>
              <Value>Připravujeme</Value>
            </Row>
            <Row>
              <Label>Silvestr/chalupa</Label>
              <Value>Připravujeme</Value>
            </Row>
            <Row>
              <Label>Vánoce/chalupa</Label>
              <Value>Připravujeme</Value>
            </Row>
          </Column>
        </TableContainer>
      </Container>
    </Layout>
  );
};

export default OverviewSection;

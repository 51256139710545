import React from "react";
import { ThemeProvider } from "styled-components";
import { defaultTheme } from "../../theme/defaultTheme";
import { colors } from "../../theme";
import { HeaderDiv, Heading, ImageDiv, Layout, UpperDiv } from "./styles";
import { useFadeAnmation } from "hooks/useFadeAnimation";

import MainTitle from "../../components/UI/MainTitle/MainTitle";
import ParagraphDiv from "../../components/ParagraphDiv/ParagraphDiv";
import ImagesSection from "./components/ImagesSection/ImagesSection";
import OverviewSection from "./components/OverviewSection/OverviewSection";
import ReservationPage from "../HomePage/components/ReservationPage/ReservationPage";
import Footer from "../../components/UI/Footer/Footer";
import Navigation from "../../components/UI/Navigation/Navigation";
import OlinkaGallery from "./components/OlinkaGallery/OlinkaGallery";

const olinkaText1 =
  "Místo, které Vám dovolí poznat na vlastní oči čarovnou krásu Jeseníků a nechat se naplno pohltit jejich kouzelnou mocí. Právě zde můžete načerpat energii odpočinkem či fyzickou aktivitou v jedné z chalup, které jsou Vám plně k dispozici, a to přímo v malebné obci Malá Morávka v podhůří Jeseníků.";

const OlinkaPage = () => {
  const { isVisible, ref } = useFadeAnmation();

  return (
    <ThemeProvider theme={defaultTheme}>
      <Navigation defaultcolor={colors.textPrimary} />
      <Layout>
        <UpperDiv>
          <HeaderDiv ref={ref} className={isVisible ? "visible" : ""}>
            <Heading>
              <MainTitle color={colors.black}>Chalupa 106</MainTitle>
            </Heading>
          </HeaderDiv>
          <ImageDiv ref={ref} className={isVisible ? "visible" : ""} />
          <ParagraphDiv
            color={colors.textPrimary}
            margintop={100}
            marginbottom={0}
          >
            {olinkaText1}
          </ParagraphDiv>
        </UpperDiv>
        <ImagesSection />
        <OverviewSection />
        <OlinkaGallery />
        <ReservationPage />
      </Layout>
      <Footer />
    </ThemeProvider>
  );
};

export default OlinkaPage;

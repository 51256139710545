import styled, { keyframes, css } from "styled-components";
import { variables } from "theme/variables";
import Masonry from "react-masonry-css";

const fadeInUp = keyframes`
  from {
    opacity: 0;
    transform: translateY(${variables.fadeUpOffset}px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const floatInRight = keyframes`
  from {
    opacity: 0;
    transform: translateX(50px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

interface IGalleryImageProps {
  animate: boolean,
}

export const Layout = styled.div`
  margin: 0px 30px;
  display: flex;
  flex-direction: column;
  max-width: 1040px;
  opacity: 0;
  visibility: hidden;

  &.visible {
    opacity: 1;
    transform: translateY(0);
    visibility: visible;
    animation: ${fadeInUp} ${variables.popUpDuration}s ease-out forwards;
  }
`;

export const TitleDiv = styled.div`
  width: 100%;
  display: flex;
  margin-bottom: 40px;
`;

export const StyledMasonry = styled(Masonry)`
  display: flex;
  flex-direction: row;

  .my-masonry-grid {
    display: flex;
    margin-right: 30px;
    width: auto;
  }
  .my-masonry-grid_column {
    background-clip: padding-box;
    margin-right: 0px;

    @media (min-width: ${variables.mobileScreen}px) {
      margin-right: 30px;
    }
  }

  .gallery-item {
    margin-bottom: 30px;
    &:hover {
      transform: scale(1.1);
      transition: transform 0.3s ease;
    }
  }
`;

export const GalleryImage = styled.img<IGalleryImageProps>`
  width: 100%;
  margin-bottom: 25px;
  cursor: pointer;
  transition: transform 0.3s ease, opacity 0.3s ease;

  animation: ${props => props.animate ? css`${floatInRight} 1s ease-out forwards` : 'none'};

  @media (min-width: ${variables.mobileScreen}px) {
    margin-bottom: 25px;
    }
`;

export const GallerySizer = styled.div`
  width: 33.333%;
`;

export const ShowMoreButton = styled.button`
  background-color: transparent;
  border-style: none;
  width: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
  font-size: 25px;
  font-family: "degular", sans-serif;
`

export const ShowMoreImg = styled.img`
transform: rotate(90deg);
height: 30px;
`
export const ShowLessImg = styled.img`
transform: rotate(-90deg);
height: 30px;
`
import React, { useState, useEffect } from "react";
import {
  Logo,
  MenuButton,
  Nav,
  Overlay,
  MenuItem,
  CloseButton,
  ExtendedLogo,
  ContactContainer,
  ContactItemDiv,
  ContactItem,
  StyledHashLink
} from "./styles";

interface INavigationProps {
  defaultcolor: string;
}

const Navigation = ({ defaultcolor }: INavigationProps) => {
  const [extendedNav, setExtendedNav] = useState(false);
  const [lastScrollY, setLastScrollY] = useState(0);
  const [showNav, setShowNav] = useState(true);
  const [scrolledDown, setScrolledDown] = useState(false);

  const toggleMenu = () => {
    setExtendedNav(!extendedNav);
  };

  const handleScroll = () => {
    const currentScrollY = window.scrollY;
    setScrolledDown(currentScrollY > 0);
    if (currentScrollY < lastScrollY) {
      setShowNav(true);
    } else if (currentScrollY > lastScrollY) {
      setShowNav(false);
      setExtendedNav(false);
    }
    setLastScrollY(currentScrollY);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, [lastScrollY]);

  return (
    <>
      <Nav shownav={showNav} scrolleddown={scrolledDown}>
        <Logo href="/" scrolleddown={scrolledDown} defaultcolor={defaultcolor}>
          NA MORAVKU
        </Logo>
        <MenuButton
          scrolleddown={scrolledDown}
          onClick={toggleMenu}
          defaultcolor={defaultcolor}
        >
          Menu
        </MenuButton>
      </Nav>
      <Overlay isvisible={extendedNav}>
        <CloseButton onClick={toggleMenu}>X</CloseButton>
        <ExtendedLogo href="/">Na moravku</ExtendedLogo>
        <MenuItem href="/">NA MORÁVKU</MenuItem>
        <MenuItem href="/chalupa-106">CHALUPA 106</MenuItem>
        <StyledHashLink to="/#tips">PŘÍRODA A OKOLÍ</StyledHashLink>
        <MenuItem href="#reservation">REZERVACE</MenuItem>
        <ContactContainer>
          <ContactItemDiv>
            <ContactItem href='mailto:info@namoravku.cz'>info@namoravku.cz</ContactItem>
            <ContactItem href='tel:+420776583856'>+420 776 583 856</ContactItem>
          </ContactItemDiv>
          <ContactItemDiv>
            <ContactItem href='https://www.instagram.com/namoravku?igsh=aTc0anNiejFzMHpi&utm_source=qr'>Instagram</ContactItem>
            <ContactItem>Facebook</ContactItem>
          </ContactItemDiv>
        </ContactContainer>
      </Overlay>
    </>
  );
};

export default Navigation;

import styled, { keyframes } from "styled-components";
import { variables } from "theme/variables";

interface ContainerProps {
  hasmargin?: boolean;
}

interface IImgProps {
  imgwidth: number;
}

const fadeInUp = keyframes`
  from {
    opacity: 0;
    transform: translateY(${variables.fadeUpOffset}px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

export const Container = styled.div<ContainerProps>`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  opacity: 0;
  visibility: hidden;

  &.visible {
    opacity: 1;
    transform: translateY(0);
    visibility: visible;
    animation: ${fadeInUp} ${variables.popUpDuration}s ease-out forwards;
  }

  @media (min-width: ${variables.mobileScreen}px) {
    flex-direction: row;
    justify-content: space-between;
    gap: 130px;
  }
`;

export const LeftContainer = styled.div<ContainerProps>`
  width: 50%;
  display: flex;
  justify-content: end;
  box-sizing: border-box;
  padding-left: ${(props) => (props.hasmargin ? "110px" : "0px")};
`;
export const RightContainer = styled.div`
  width: 50%;
  display: flex;
  justify-content: start;
  box-sizing: border-box;
`;

export const ImageContainer = styled.div`
  width: 100%;
`;

export const Image = styled.img<IImgProps>`
  max-width: 100%;
  height: auto;
`;

export const TextContainer = styled.div`
  width: 100%;
`;

export const Text = styled.p`
  font-size: 18px;
  line-height: 20px;
  max-width: 315px;
  text-align: center;
  @media (min-width: ${variables.mobileScreen}px) {
    text-align: start;
    font-size: 20px;
    line-height: 26px;
  }
`;

export const TextDiv = styled.div`
  font-size: 18px;
  line-height: 20px;
  max-width: 315px;
  text-align: center;
  @media (min-width: ${variables.mobileScreen}px) {
    text-align: start;
    font-size: 20px;
    line-height: 26px;
  }
`;

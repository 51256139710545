import React, { useState, useEffect, useRef } from "react";
import {
  BottomDiv,
  BottomLeftContainer,
  BottomLeftText,
  BottomRightImage,
  Layout,
  MiddleContainer,
  MiddleDiv,
  MiddleImage,
  UpperDiv,
  UpperLeftContainer,
  UpperLeftImage,
  UpperRightContainer,
  UpperRightImage,
  UpperRightText,
  HorizontalScrollContainer,
  Image,
  Dot,
  DotsContainer,
} from "./styles";
import { variables } from "theme/variables";
import { useFadeAnmation } from "hooks/useFadeAnimation";

import Image1 from "../../../../assets/OlinkaPage/ImagesSection/Olinka1.jpg";
import Image2 from "../../../../assets/OlinkaPage/ImagesSection/Olinka2.jpg";
import Image3 from "../../../../assets/OlinkaPage/ImagesSection/Olinka3.jpg";
import Image4 from "../../../../assets/OlinkaPage/ImagesSection/Olinka4.jpg";
import ItemList from "components/UI/ItemList/ItemList";

const text1 =
  "Fusce tellus. Fusce suscipit libero eget elit. Etiam quis quam. Fusce consectetuer risus a nunc. In convallis. Proin pede metus, vulputate nec, fermentum fringilla, vehicula vitae, justo.";

const text2 =
  "Na chalupě č. 106 se naskýtá ideální útočiště pro milovníky přírody a klidu. Tato útulná chalupa poskytuje pohodlí a soukromí s autentickým horským charakterem. Interiér je ztvárněn s důrazem na dřevěné prvky, které doplňují kachlová kamna pro teplo a atmosféru. K dispozici jsou dvě ložnice, plně vybavená kuchyň a obývací pokoj s dostatkem místa pro relaxaci. Venkovní terasa nabízí krásný výhled na okolní lesy a je ideálním místem pro ranní kávu nebo večerní posezení u vína.";

const ImagesSection = () => {
  const [isMobile, setIsMobile] = useState(
    window.innerWidth < variables.mobileScreen
  );
  const { isVisible, ref } = useFadeAnmation();

  const [currentIndex1, setCurrentIndex1] = useState<number>(0);
  const [currentIndex2, setCurrentIndex2] = useState<number>(0);
  const scrollContainerRef1 = useRef<HTMLDivElement>(null);
  const scrollContainerRef2 = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < variables.mobileScreen);
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const handleScroll1 = () => {
      const scrollPosition = scrollContainerRef1.current?.scrollLeft;
      const imageWidth = scrollContainerRef1.current?.clientWidth;
      if (
        typeof scrollPosition === "number" &&
        typeof imageWidth === "number"
      ) {
        const index = Math.round(scrollPosition / imageWidth);
        setCurrentIndex1(index);
      }
    };

    const handleScroll2 = () => {
      const scrollPosition = scrollContainerRef2.current?.scrollLeft;
      const imageWidth = scrollContainerRef2.current?.clientWidth;
      if (
        typeof scrollPosition === "number" &&
        typeof imageWidth === "number"
      ) {
        const index = Math.round(scrollPosition / imageWidth);
        setCurrentIndex2(index);
      }
    };

    const container1 = scrollContainerRef1.current;
    const container2 = scrollContainerRef2.current;

    if (container1) container1.addEventListener("scroll", handleScroll1);
    if (container2) container2.addEventListener("scroll", handleScroll2);

    return () => {
      if (container1) container1.removeEventListener("scroll", handleScroll1);
      if (container2) container2.removeEventListener("scroll", handleScroll2);
    };
  }, []);

  return (
    <Layout ref={ref} className={isVisible ? "visible" : ""}>
      {isMobile ? (
        <>
          <UpperDiv>
            <HorizontalScrollContainer ref={scrollContainerRef1}>
              {[Image1, Image2].map((src, index) => (
                <Image key={index} src={src} />
              ))}
            </HorizontalScrollContainer>
            <DotsContainer>
              {[Image1, Image2].map((_, index) => (
                <Dot key={index} active={currentIndex1 === index} />
              ))}
            </DotsContainer>
            {/* <UpperRightText>{text1}</UpperRightText> */}
            <ItemList />
          </UpperDiv>
          <BottomDiv>
            <HorizontalScrollContainer ref={scrollContainerRef2}>
              {[Image3, Image4].map((src, index) => (
                <Image key={index} src={src} />
              ))}
            </HorizontalScrollContainer>
            <DotsContainer>
              {[Image3, Image4].map((_, index) => (
                <Dot key={index} active={currentIndex2 === index} />
              ))}
            </DotsContainer>
            <BottomLeftText>{text2}</BottomLeftText>
          </BottomDiv>
        </>
      ) : (
        <>
          <UpperDiv>
            <UpperLeftContainer>
              <UpperLeftImage src={Image1} />
            </UpperLeftContainer>
            <UpperRightContainer>
              <UpperRightImage src={Image2} />
              {/* <UpperRightText>{text1}</UpperRightText> */}
              <ItemList />
            </UpperRightContainer>
          </UpperDiv>
          <MiddleDiv>
            <MiddleContainer>
              <MiddleImage src={Image3} />
            </MiddleContainer>
          </MiddleDiv>
          <BottomDiv>
            <BottomLeftContainer>
              <BottomLeftText>{text2}</BottomLeftText>
            </BottomLeftContainer>
            <BottomRightImage src={Image4} />
          </BottomDiv>
        </>
      )}
    </Layout>
  );
};

export default ImagesSection;

export const colors = {
    primary: '#F7F6F1',
    background: '#ffffff',
    gray: '#D9DCE1',
    borderGray: '#312C28',
    textPrimary: '#191818',
    background1: '#F2F2F2',
    black: '#000000',
    black2: '#292929',
    white: '#fff',
    transparent: 'transparent',
  };
import styled, { keyframes } from "styled-components";
import { colors } from "theme";
import { variables } from "theme/variables";

const fadeInUp = keyframes`
  from {
    opacity: 0;
    transform: translateY(${variables.fadeUpOffset}px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

export const Layout = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 50px 30px;
  opacity: 0;
  visibility: hidden;

  &.visible {
    opacity: 1;
    transform: translateY(0);
    visibility: visible;
    animation: ${fadeInUp} ${variables.popUpDuration}s ease forwards;
  }

  @media (min-width: ${variables.mobileScreen}px) {
    margin: 0px;
    padding-top: 175px;
    padding-bottom: 206px;
  }
`;

export const NavigationLinks = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 80px;
`;

export const NavLink = styled.a`
  color: ${colors.textPrimary};
  text-align: center;
  font-size: 25px;
  text-decoration: none;
  border-bottom: 2px solid transparent;
  cursor: pointer;
  transition: transform 0.3s ease;

  &:hover {
    border-bottom: 2px solid ${colors.textPrimary};
    transform: scale(1.2);
  }
`;

export const ImageMap = styled.img`
  width: 100%;
  height: auto;
  margin: 90px 0px;
  transition: transform 0.3s ease;
  cursor: pointer;

  @media (min-width: ${variables.mobileScreen}px) {
    max-width: 545px;
  }

  &:hover {
    transform: scale(1.2);
  }
`;

import styled, {keyframes} from "styled-components";
import { colors } from "../../theme";
import { variables } from "../../theme/variables";

const fadeInUp = keyframes`
  from {
    opacity: 0;
    transform: translateY(${variables.fadeUpOffset}px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

interface IDotProps {
  active: boolean,
}

export const Container = styled.div`
width: 100%;
  display: flex;
  flex-direction: column;
  opacity: 0;
  visibility: hidden;

  &.visible {
    opacity: 1;
    transform: translateY(0);
    visibility: visible;
    animation: ${fadeInUp} ${variables.popUpDuration}s ease-out forwards;
  }

  @media (min-width: ${variables.mobileScreen}px) {
    flex-direction: row;
    justify-content: space-between;
    gap: 127px;
  }
`;

export const SubContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media (min-width: 768px) {
    justify-content: space-between;
    align-items: start;
  }
`;

export const LeftImageContainer = styled.div`
  width: 100%;
  margin-top: 82px;
`;

export const RightImageContainer = styled.div`
  width: 100%;
  margin-bottom: 97px;
`;

export const Image = styled.img`
  width: 100%;
  height: auto;
  object-fit: cover;
`;

export const Text = styled.p`
  max-width: 410px;
  text-align: center;
  font-size: 20px;
  line-height: 26px;
  color: ${colors.textPrimary};
  margin-bottom: 64px;
  @media (min-width: ${variables.mobileScreen}px) {
    text-align: left;
  }
`;

export const TextDiv = styled.div`
  width: 100%;
  text-align: center;
  font-size: 20px;
  line-height: 26px;
  color: ${colors.textPrimary};
  margin-bottom: 64px;
  @media (min-width: ${variables.mobileScreen}px) {
    text-align: left;
  }
`;

export const ButtonDiv = styled.div`
  margin-bottom: 50px;
`;

export const OuterScrollbarContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px 20px;
`;

export const HorizontalScrollContainer = styled.div`
  display: flex;
  overflow-x: auto;
  width: 100%;
  &::-webkit-scrollbar {
    display: none;
  }
  scrollbar-width: none;
`;

export const Dot = styled.span<IDotProps>`
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background: ${props => (props.active ? 'black' : 'grey')};
  margin: 0 5px;
  display: inline-block;
  cursor: pointer;
`;

export const DotsContainer = styled.div`
  text-align: center;
  margin-top: 20px;
`;

import styled, {keyframes} from "styled-components";
import { variables } from "theme/variables";

import olinkaMainImg from "../../assets/OlinkaPage/OlinkaMainImg.jpg";

const fadeInUp = keyframes`
  from {
    opacity: 0;
    transform: translateY(${variables.fadeUpOffset}px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

export const Layout = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 175px;
  width: 100%;
`

export const HeaderDiv = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 105px;
  padding-bottom: 0px;
  opacity: 0;
  visibility: hidden;

  @media (min-width: ${variables.mobileScreen}px) {
    padding-bottom: 75px;
  }

  &.visible {
    opacity: 1;
    transform: translateY(0);
    visibility: visible;
    animation: ${fadeInUp} ${variables.popUpDuration}s ease-out forwards;
  }
`;

export const Heading = styled.div`
  height: 145px;
`;

export const ImageDiv = styled.div`
  width: 100%;
  height: 600px;
  background-image: url(${olinkaMainImg});
  background-size: cover;
  background-position: center;
  opacity: 0;
  visibility: hidden;

  &.visible {
    opacity: 1;
    transform: translateY(0);
    visibility: visible;
    animation: ${fadeInUp} ${variables.popUpDuration}s ease-out forwards;
  }
`;

export const UpperDiv = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const TextDiv = styled.div`
height: 410px;
`
import styled, { keyframes } from "styled-components";
import { variables } from "theme/variables";

interface ContainerProps {
  backgroundimgsrc: string;
}

const popUpAnimation = keyframes`
  from {
    opacity: 0;
    transform: translateY(${variables.fadeUpOffset}px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

export const Container = styled.div<ContainerProps>`
  height: 300px;
  width: 100%;
  background-image: url(${(props) => props.backgroundimgsrc});
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  visibility: hidden;

  &.visible {
    animation: ${popUpAnimation} ${variables.popUpDuration}s ease forwards;
    visibility: visible;
  }

  @media (min-width: 768px) {
    height: 645px;
  }
`;

import styled, { keyframes } from "styled-components";
import { variables } from "theme/variables";

const fadeInUp = keyframes`
  from {
    opacity: 0;
    transform: translateY(${variables.fadeUpOffset}px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

export const Layout = styled.div`
  margin: 0px 30px;
  display: flex;
  justify-content: center;
  max-width: ${variables.maxDivWidth}px;
  opacity: 0;
  visibility: hidden;

  &.visible {
    opacity: 1;
    transform: translateY(0);
    visibility: visible;
    animation: ${fadeInUp} ${variables.popUpDuration}s ease-out forwards;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 100%;
`;

export const TitleDiv = styled.div`
  width: 100%;
  display: flex;
  justify-content: start;
`;

export const TableContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 45px;
  @media (min-width: ${variables.mobileScreen}px) {
    flex-direction: row;
    justify-content: space-between;
    gap: 150px;
  }
`;
export const Column = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  @media (min-width: ${variables.mobileScreen}px) {
    width: 445px;
  }
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  border-top: 1px solid rgba(49, 44, 40, 0.2);
  padding: 16px 0;
`;

export const Item = styled.span`
  font-size: 20px;
  line-height: 28px;
`;

export const Label = styled(Item)`
  /* Additional style for label if needed */
`;

export const Value = styled(Item)`
  /* Additional style for value if needed */
`;

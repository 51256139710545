import styled from "styled-components";
import LandingPageImage from "../../../../assets/HomePage/uvod-bg.jpg";

export const Container = styled.div`
  height: 100vh;
  width: 100%;
  background-image: url(${LandingPageImage});
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

// export const ArrowImg = styled.img`
//   transform: rotate(90deg);
//   height: 50px;
//   filter: hue-rotate(90deg);
// `;

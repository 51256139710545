import React, { useEffect, useState, useRef } from "react";
import {
  ButtonDiv,
  Container,
  HorizontalScrollContainer,
  Image,
  LeftImageContainer,
  OuterScrollbarContainer,
  RightImageContainer,
  SubContainer,
  Text,
  Dot,
  DotsContainer,
  TextDiv,
} from "./styles";
import { colors } from "../../theme";
import { variables } from "theme/variables";
import { useFadeAnmation } from "hooks/useFadeAnimation";

import SecondaryTitle from "../UI/SecondaryTitle/SecondaryTitle";
import MoreButton from "../UI/MoreButton/MoreButton";

interface ICottageDivProps {
  title: string;
  leftImgPath: string;
  rightImgPath: string;
  text: string;
  linkTo: string;
}

const CottageDiv = ({
  title,
  leftImgPath,
  rightImgPath,
  text,
  linkTo,
}: ICottageDivProps) => {
  const [isMobile, setIsMobile] = useState(
    window.innerWidth < variables.mobileScreen
  );
  const { isVisible, ref } = useFadeAnmation();

  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const scrollContainerRef = useRef<HTMLDivElement>(null);

  //const images = [{ src: leftImgPath }, { src: rightImgPath }];

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < variables.mobileScreen);
    };

    window.addEventListener("resize", handleResize);

    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [title]);

  const handleScroll = () => {
    const container = scrollContainerRef.current;
    const scrollPosition = container ? container.scrollLeft : 0;
    const imageWidth = container ? container.offsetWidth : 1;
    const index = Math.round(scrollPosition / imageWidth);
    setCurrentIndex(index);
  };

  useEffect(() => {
    const container = scrollContainerRef.current;
    if (container) {
      container.addEventListener("scroll", handleScroll);
    }
    return () => {
      if (container) {
        container.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);

  return (
    <Container ref={ref} id={title} className={isVisible ? "visible" : ""}>
      {isMobile ? (
        <SubContainer>
          <SecondaryTitle color={colors.black}>{title}</SecondaryTitle>
          <Text>{text}</Text>
          <ButtonDiv>
            <MoreButton linkTo={linkTo} />
          </ButtonDiv>
          <OuterScrollbarContainer>
            <HorizontalScrollContainer ref={scrollContainerRef}>
              {[leftImgPath, rightImgPath].map((src, index) => (
                <Image key={index} src={src} />
              ))}
            </HorizontalScrollContainer>
            <DotsContainer>
              {[leftImgPath, rightImgPath].map((_, index) => (
                <Dot key={index} active={currentIndex === index} />
              ))}
            </DotsContainer>
          </OuterScrollbarContainer>
        </SubContainer>
      ) : (
        <>
          <SubContainer>
            <SecondaryTitle color={colors.black}>{title}</SecondaryTitle>
            <LeftImageContainer>
              <Image src={leftImgPath} />
            </LeftImageContainer>
          </SubContainer>
          <SubContainer>
            <RightImageContainer>
              <Image src={rightImgPath} />
            </RightImageContainer>
            <Text>{text}</Text>
            <ButtonDiv>
              <MoreButton linkTo={linkTo} />
            </ButtonDiv>
          </SubContainer>
        </>
      )}
    </Container>
  );
};

export default CottageDiv;

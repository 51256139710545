import styled from "styled-components";
import { variables } from "theme/variables";

export const Layout = styled.div`
  max-width: ${variables.maxDivWidth}px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 203px;
  margin-top: 203px;
  margin-bottom: 203px;
  margin-left: 30px;
  margin-right: 30px;
`;

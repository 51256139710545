import React from 'react'
import { Layout } from './styles'
import CottageDiv from '../../../../components/CottageDiv/CottageDiv'

import OlinkaI from '../../../../assets/OlinkaCottage/OlinkaI17.jpg'
import OlinkaII from '../../../../assets/OlinkaCottage/OlinkaII18.jpg'

let olinkaText = 'Chalupa č. 106 na Moravku je ideální pro milovníky přírody a klidu. Nabízí dřevěný interiér s kachlovými kamny, dvě ložnice, plně vybavenou kuchyň a obývací pokoj. Venkovní terasa poskytuje výhled na lesy, ideální pro ranní kávu nebo večerní posezení s vínem. Přináší dokonalé pohodlí a soukromí pro relaxaci v náručí přírody.'


const Cottages = () => {
  return (
    <Layout>
        <CottageDiv title='Chalupa 106' leftImgPath={OlinkaI} rightImgPath={OlinkaII} text={olinkaText} linkTo='/chalupa-106' />
    </Layout>
  )
}

export default Cottages

import React from "react";
import { ThemeProvider } from "styled-components";
import { defaultTheme } from "../../theme/defaultTheme";
import { colors } from "../../theme";

import ExplorePageImg from "../../assets/HomePage/ExplorePage.jpg";
import ViewImg from "../../assets/HomePage/SimpleDivI22.jpg";

import LandingPage from "./components/Landing/LandingPage";
import ParagraphDiv from "../../components/ParagraphDiv/ParagraphDiv";
import ExplorePage from "./components/Explore/ExplorePage";
import Cottages from "./components/Cottages/Cottages";
import OurTips from "./components/OurTips/OurTips";
import ReservationPage from "./components/ReservationPage/ReservationPage";
import FindUs from "./components/FindUsPage/FindUs";
import VisitEshop from "./components/VisitEshop/VisitEshop";
import Footer from "../../components/UI/Footer/Footer";
import Navigation from "../../components/UI/Navigation/Navigation";
import { Layout } from "./styles";

const HomePage = () => {



  return (
    <ThemeProvider theme={defaultTheme}>
      <Navigation defaultcolor={colors.primary} />
      <Layout>
        <LandingPage />
        <ParagraphDiv
          color={colors.textPrimary}
          margintop={130}
          marginbottom={177}
        >
          Místo, které Vám dovolí poznat na vlastní oči čarovnou krásu Jeseníků a nechat se naplno pohltit jejich kouzelnou mocí. Právě zde můžete načerpat energii odpočinkem či fyzickou aktivitou v jedné z chalup, které jsou Vám plně k dispozici, a to přímo v malebné obci Malá Morávka v podhůří Jeseníků.
        </ParagraphDiv>
        <ExplorePage hasTitle={true} backgroundimgsrc={ExplorePageImg} />
        <Cottages />
        <ExplorePage hasTitle={false} backgroundimgsrc={ViewImg} />
        <OurTips />
        <ReservationPage />
        <FindUs />
        {/* <VisitEshop /> */}
        <Footer />
      </Layout>
    </ThemeProvider>
  );
};

export default HomePage;

import React from "react";
import { Container } from "./styles";
import { colors } from "theme";
import { useFadeAnmation } from "hooks/useFadeAnimation";
import MainTitle from "components/UI/MainTitle/MainTitle";

interface IExplorePageProps {
  hasTitle: boolean;
  backgroundimgsrc: string;
}

const ExplorePage = ({ hasTitle, backgroundimgsrc }: IExplorePageProps) => {
  const { isVisible, ref } = useFadeAnmation();

  return (
    <Container
      ref={ref}
      backgroundimgsrc={backgroundimgsrc}
      id={hasTitle ? "explore" : "another-unique-id"}
      className={isVisible ? "visible" : ""}
    >
      {hasTitle ? (
        <MainTitle color={colors.primary}>Dokonalá nedokonalost</MainTitle>
      ) : null}
    </Container>
  );
};

export default ExplorePage;

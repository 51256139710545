import styled, {keyframes} from "styled-components";
import { colors } from "../../../../theme";
import { variables } from "theme/variables";

const fadeInUp = keyframes`
  from {
    opacity: 0;
    transform: translateY(${variables.fadeUpOffset}px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

interface IDotProps {
  active: boolean,
}

export const Layout = styled.div`
  display: flex;
  flex-direction: column;
  gap: 190px;
  max-width: ${variables.maxDivWidth}px;
  margin: 0px 30px;
  opacity: 0;
  visibility: hidden;

  &.visible {
    opacity: 1;
    transform: translateY(0);
    visibility: visible;
    animation: ${fadeInUp} ${variables.popUpDuration}s ease-out forwards;
  }
`;

export const UpperDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (min-width: ${variables.mobileScreen}px) {
    flex-direction: row;
    justify-content: space-between;
    align-items: stretch;
    gap: 130px;
  }
`;

export const UpperLeftContainer = styled.div`
  padding-top: 160px;
  max-width: 428px;
`;

export const UpperLeftImage = styled.img`
  height: auto;
  width: 100%;
`;

export const UpperRightContainer = styled.div`
  max-width: 520px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const UpperRightImage = styled.img`
  height: auto;
  width: 100%;
`;

export const UpperRightText = styled.p`
  max-width: 410px;
  text-align: center;
  color: ${colors.textPrimary};
  font-size: 20px;
  line-height: 26px;
  @media (min-width: ${variables.mobileScreen}px) {
    text-align: left;
  }
`;

export const MiddleDiv = styled.div`
  display: flex;
  justify-content: center;
`;

export const MiddleContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: start;
`;

export const MiddleImage = styled.img`
  height: auto;
  max-width: 671px;
`;

export const BottomDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (min-width: ${variables.mobileScreen}px) {
    flex-direction: row;
    justify-content: space-between;
    align-items: stretch;
    gap: 130px;
  }
`;
export const BottomLeftContainer = styled.div`
  padding-top: 160px;
  padding-right: 130px;
`;

export const BottomLeftText = styled.p`
  max-width: 410px;
  text-align: center;
  color: ${colors.textPrimary};
  font-size: 20px;
  line-height: 26px;
  @media (min-width: ${variables.mobileScreen}px) {
    text-align: left;
  }
`;

export const BottomRightImage = styled.img`
  height: auto;
  max-width: 430px;
`;

export const HorizontalScrollContainer = styled.div`
  display: flex;
  overflow-x: auto;
  width: 100%;
  &::-webkit-scrollbar {
    display: none;
  }
  scrollbar-width: none;
`;

export const Image = styled.img`
  width: 100%;
  height: auto;
  object-fit: cover;
`;

export const Dot = styled.span<IDotProps>`
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background: ${props => (props.active ? 'black' : 'grey')};
  margin: 0 5px;
  display: inline-block;
  cursor: pointer;
`;

export const DotsContainer = styled.div`
  text-align: center;
  margin-top: 20px;
`;

import React from "react";
import { Layout } from "./styles";
import { colors } from "theme";

import Tip1 from "assets/HomePage/Tips/TipsI.jpg";
import Tip2 from "assets/HomePage/Tips/TipsII53.jpg";
import Tip3 from "assets/HomePage/Tips/TipsIII54.jpg";
import Tip4 from "assets/HomePage/Tips/karlova-studanka.jpg"

import MainTitle from "components/UI/MainTitle/MainTitle";
import TipsDiv from "components/TipsDiv/TipsDiv";
import ParagraphDiv from "components/ParagraphDiv/ParagraphDiv";

const text1 =
  "Kopřivná resort pod Pradědem - leží v horní části Malé Morávky u hotelu Kopřivná v samém srdci Hrubého Jeseníku s nejčistším vzduchem ve střední Evropě. Nabízí: jízdní kola, koloběžky, Funpark, Bikepark, Dětská aréna, Ski areál Kopřivná - lyže, snowboard;středisko nabízí po celou zimní sezónu výborné lyžařské podmínky";

const text2 =
  "Naučná stezka Bílá Opava procházejte se po mnoha mostcích, schodištích a lávkách po březích Bílé Opavy – nejkrásnější horské bystřiny Jeseníků.Kromě několika vodopádů, četných kaskád a peřejí, skalních útvarů a romantických zákoutí smrkového pralesa Vám naučná stezka Bílá Opava na 13 informačních tabulích nabízí i základní informace o přírodě této jedinečné rezervace. Stezka je dlouhá celkem šest kilometrů a prochází těsně kolem vodopádů.";

const text3 =
  "Rozhledna na Pradědu – nejvyšší dostupné místo v České republice. Na nejvyšší hoře Hrubého Jeseníku, Pradědu, stojí rozhledna, jejíž stavba začala již v roce 1904. Věž dosahuje výšky úctyhodných 162 metrů. Vyhlídková terasa je umístěna ve výšce 1 563 m n. m. Z vyhlídkové terasy se vám nabídne pohled především na Jeseníky, za dobrého počasí i na Vysoké Tatry.";

const text4 = "Karlova Studánka - navštivte ojediněle přebudované lázně obklopené horskou přírodou, které leží v panenské přírodě na východní straně nejvyšší hory Jeseníků Pradědu, v údolí horské říčky Bílé Opavy, a pyšní se nejčistším vzduchem ve střední Evropě.";

const OurTips = () => {
  return (
    <Layout id="tips">
      <MainTitle color={colors.black2}>PŘÍRODA A OKOLÍ</MainTitle>
      <ParagraphDiv color={colors.textPrimary} margintop={50} marginbottom={0}>
        Jeseníky s podhůřím jsou horským rájem s mnoha přírodními, historickými
        i technickými poklady. Čeká vás zde krásná příroda, spousta tras pro
        pěší i cyklisty, rašeliniště, rozhledny, vodopády i lázně s blahodárnými
        procedurami. Jeseníky nabízejí nejčistší vzduch ve střední Evropě. V
        létě zde najdete stovky dobře značených tras jak pro pěší, tak pro
        cykloturistiku. Nejen vyhlášená střediska zimních sportů, ale i lázně,
        památky a kulturní akce jsou důvodem, proč je navštívit i v zimě.
        Jeseníky jsou drsné hory s hlubokými lesy, čistým a ostrým vzduchem,
        krásnými vrcholy a majestátním Pradědem.
      </ParagraphDiv>
      <ParagraphDiv color={colors.textPrimary} margintop={0} marginbottom={50}>
        V zimním období rozsáhlé možnosti lyžařských aktivit a v letním
        turistika či cykloturistika. Kopřivná resort pod Pradědem - Půjčovna u
        LD Karlov Ski Expres - půjčovna vybavení pro lyžaře, elektrokola,
        sjezdová kola, horské buginy, elektrokoloběžky, doplňky.
      </ParagraphDiv>
      <TipsDiv
        text={text1}
        imgPosition="left"
        imgPath={Tip1}
        imgwidth={539}
        hasLeftMargin={false}
      />
      <TipsDiv
        text={text2}
        imgPosition="right"
        imgPath={Tip2}
        imgwidth={428}
        hasLeftMargin={true}
      />
      <TipsDiv
        text={text3}
        imgPosition="left"
        imgPath={Tip3}
        imgwidth={429}
        hasLeftMargin={true}
      />
      <TipsDiv
        text={text4}
        imgPosition="right"
        imgPath={Tip4}
        imgwidth={428}
        hasLeftMargin={true}
      />
    </Layout>
  );
};

export default OurTips;

import React, { useState, useEffect } from "react";
import FsLightbox from "fslightbox-react";
import { useFadeAnmation } from "hooks/useFadeAnimation";
import { colors } from "theme";
import { variables } from "theme/variables";

import {
  Layout,
  GalleryImage,
  StyledMasonry,
  TitleDiv,
  ShowMoreButton,
  ShowMoreImg,
  ShowLessImg,
} from "./styles";

import OlinkaGallery1 from "assets/OlinkaPage/Gallery/OlinkaGallery1.jpg";
import OlinkaGallery2 from "assets/OlinkaPage/Gallery/OlinkaGallery2.jpg";
import OlinkaGallery3 from "assets/OlinkaPage/Gallery/OlinkaGallery3.jpg";
import OlinkaGallery4 from "assets/OlinkaPage/Gallery/OlinkaGallery4.jpg";
import OlinkaGallery5 from "assets/OlinkaPage/Gallery/OlinkaGallery5.jpg";
import Arrow from "assets/UI/Arrow79.png";

import SecondaryTitle from "components/UI/SecondaryTitle/SecondaryTitle";

const breakpointColumnsObj = {
  default: 3,
  1100: 3,
  700: 1,
  500: 1,
};

const OlinkaGallery = () => {
  const [toggler, setToggler] = useState(false);
  const [currentImage, setCurrentImage] = useState(0);
  const { isVisible, ref } = useFadeAnmation();
  const [isMobile, setIsMobile] = useState(
    window.innerWidth < variables.mobileScreen
  );
  const [showMore, setShowMore] = useState(false);

  const images = [
    OlinkaGallery1,
    OlinkaGallery2,
    OlinkaGallery3,
    OlinkaGallery4,
    OlinkaGallery5,
    OlinkaGallery4,
  ];

  const imagesShortened = [OlinkaGallery1, OlinkaGallery2];

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < variables.mobileScreen);
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleImageClick = (index: number) => {
    setCurrentImage(index);
    setToggler(!toggler);
  };

  const hadleShowMore = () => {
    setShowMore(true);
  };
  const hadleShowLess = () => {
    setShowMore(false);
  };

  return (
    <>
      <Layout ref={ref} className={isVisible ? "visible" : ""}>
        <TitleDiv>
          <SecondaryTitle color={colors.black}> Galerie</SecondaryTitle>
        </TitleDiv>
        {isMobile ? (
          <>
            {showMore ? (
              <>
                {images.map((src, index) => (
                  <GalleryImage
                    key={index}
                    src={src}
                    alt={`Gallery item ${index}`}
                    className="gallery-item"
                    onClick={() => handleImageClick(index)}
                    animate={showMore && index >= imagesShortened.length}
                  />
                ))}
                <ShowMoreButton onClick={hadleShowLess}>
                  <ShowLessImg src={Arrow} />
                  <span>Méně</span>
                </ShowMoreButton>
              </>
            ) : (
              <>
                {imagesShortened.map((src, index) => (
                  <GalleryImage
                    key={index}
                    src={src}
                    alt={`Gallery item ${index}`}
                    className="gallery-item"
                    onClick={() => handleImageClick(index)}
                    animate={showMore && index >= imagesShortened.length}
                  />
                ))}
                <ShowMoreButton onClick={hadleShowMore}>
                  <span>Více</span>
                  <ShowMoreImg src={Arrow} />
                </ShowMoreButton>
              </>
            )}
          </>
        ) : (
          <StyledMasonry
            breakpointCols={breakpointColumnsObj}
            className="my-masonry-grid"
            columnClassName="my-masonry-grid_column"
          >
            {images.map((src, index) => (
              <GalleryImage
                key={index}
                src={src}
                alt={`Gallery item ${index}`}
                className="gallery-item"
                onClick={() => handleImageClick(index)}
                animate={showMore && index >= imagesShortened.length}
              />
            ))}
          </StyledMasonry>
        )}
      </Layout>
      <FsLightbox toggler={toggler} sources={images} slide={currentImage + 1} />
    </>
  );
};

export default OlinkaGallery;

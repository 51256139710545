import React from 'react';
import { ImageMap, Layout, NavLink, NavigationLinks } from './styles';
import { colors } from '../../../../theme';
import { useFadeAnmation } from 'hooks/useFadeAnimation';

import mapImg from '../../../../assets/HomePage/Map.png';

import MainTitle from '../../../../components/UI/MainTitle/MainTitle';

const FindUs = () => {
  const { isVisible, ref } = useFadeAnmation();

  return (
    <Layout ref={ref} className={isVisible ? 'visible' : ''}>
        <MainTitle color={colors.black2}>Kde nás najdete</MainTitle>
        <NavigationLinks>
            <NavLink href="https://www.google.com/maps/place/Mal%C3%A1+Mor%C3%A1vka+106,+793+36+Mal%C3%A1+Mor%C3%A1vka/@50.0321431,17.3069373,17z/data=!3m1!4b1!4m6!3m5!1s0x471220b25562e965:0x9af8b293a2b47373!8m2!3d50.0321432!4d17.3118082!16s%2Fg%2F11c1fx9j88?entry=ttu" target="_blank" rel="noopener noreferrer">Malá Morávka 106, 793 36 Malá Morávka</NavLink>
        </NavigationLinks>
        <a href="https://www.google.com/maps/place/Mal%C3%A1+Mor%C3%A1vka+106,+793+36+Mal%C3%A1+Mor%C3%A1vka/@50.0321431,17.3069373,17z/data=!3m1!4b1!4m6!3m5!1s0x471220b25562e965:0x9af8b293a2b47373!8m2!3d50.0321432!4d17.3118082!16s%2Fg%2F11c1fx9j88?entry=ttu" target="_blank" rel="noopener noreferrer"><ImageMap src={mapImg} /></a>
    </Layout>
  )
}

export default FindUs

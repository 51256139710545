import React, { useState, useEffect } from "react";
import {
  Container,
  Image,
  ImageContainer,
  LeftContainer,
  RightContainer,
  Text,
  TextContainer,
} from "./styles";

import { variables } from "theme/variables";
import { useFadeAnmation } from "hooks/useFadeAnimation";

interface ITipsDivProps {
  text: string;
  imgPosition: "left" | "right";
  imgPath: string;
  imgwidth: number;
  hasLeftMargin: boolean;
}

const TipsDiv = ({
  text,
  imgPosition,
  imgPath,
  imgwidth,
  hasLeftMargin,
}: ITipsDivProps) => {
  const [isMobile, setIsMobile] = useState(
    window.innerWidth < variables.mobileScreen
  );
  const { isVisible, ref } = useFadeAnmation();

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < variables.mobileScreen);
    };

    window.addEventListener("resize", handleResize);

    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  
  return (
    <Container ref={ref} className={isVisible ? 'visible' : ''}>
      {isMobile ? (
        <>
          <Text>{text}</Text>
          <Image src={imgPath} imgwidth={imgwidth} />
        </>
      ) : (
        <>
          <LeftContainer hasmargin={hasLeftMargin}>
            {imgPosition === "left" ? (
              <ImageContainer>
                <Image src={imgPath} imgwidth={imgwidth} />
              </ImageContainer>
            ) : (
              <TextContainer>
                <Text>{text}</Text>
              </TextContainer>
            )}
          </LeftContainer>
          <RightContainer>
            {imgPosition === "left" ? (
              <TextContainer>
                <Text>{text}</Text>
              </TextContainer>
            ) : (
              <ImageContainer>
                <Image src={imgPath} imgwidth={imgwidth} />
              </ImageContainer>
            )}
          </RightContainer>
        </>
      )}
    </Container>
  );
};

export default TipsDiv;

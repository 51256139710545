import React from 'react';

import { Container } from './styles';
import MainTitle from '../../../../components/UI/MainTitle/MainTitle';
import { colors } from '../../../../theme';

const LandingPage = () => {
  return (
    <Container id='landing'>
      <MainTitle color={colors.primary}>chalupa<br /> na moravku</MainTitle>
      {/* <ArrowImg src={Arrow} /> */}
    </Container>
  )
}

export default LandingPage
